import React, { useEffect, useLayoutEffect, useRef, useState } from "react"
import { useGLTF } from "@react-three/drei"
import { Box3, Group, Vector3 } from "three"
import { PrimitiveProps } from "@react-three/fiber"
import { useObjectRotation } from "./useObjectRotation"
import { useSceneAnimation } from "./useSceneAnimation"
import { useCamera } from "./Camera"
import { useSceneTransparency } from "./useSceneTransparency"

export type AnimationControls = {
  play?: () => void
  reverse?: () => void
  opacity: number
  setOpacity?: (opacity: number) => void
}

export const Glb: React.FC<{
  url: string
  rotate?: boolean
  onLoaded?: () => void
  setAnimationControls?: (controls?: AnimationControls) => void
}> = ({ url, rotate, onLoaded, setAnimationControls }) => {
  const object = useGLTF(url)
  const [scene, setScene] = useState<Group>()
  useEffect(() => {
    if (object?.scene) setScene(object.scene.clone(true))
  }, [object, object?.scene])

  const ref = useRef<PrimitiveProps>()
  useObjectRotation(ref, false)
  const animationControls = useSceneAnimation(scene, object?.animations)
  const [opacity, setOpacity] = useSceneTransparency(scene)

  useLayoutEffect(() => {
    onLoaded?.()
  }, [])

  useEffect(() => {
    if (
      !animationControls ||
      !animationControls.play ||
      !animationControls.reverse
    )
      setAnimationControls?.(undefined)
    else {
      // @ts-ignore
      setAnimationControls?.({ ...animationControls, opacity, setOpacity })
    }
  }, [animationControls])

  const { camera, controls } = useCamera()
  useEffect(() => {
    if (scene) {
      const center = new Vector3()
      new Box3().setFromObject(scene).getCenter(center)
      camera?.lookAt(center)
      if (controls) {
        controls.target = center
        controls.update()
      }
    }
  }, [camera, controls, scene])

  return scene ? <primitive ref={ref} object={scene} /> : null
}
