import React, { useContext } from "react"
import * as S from "./styled"
import { Checkbox } from "../../../../components/Checkbox"
import { useForm } from "react-hook-form"
import { useInterests } from "../../../../context/InterestsContext"
import { EventContext } from "../../../../context/EventContext"
import { useTranslation } from "react-i18next"

export const CheckoutForm: React.FC = () => {
  const { t } = useTranslation()
  const { submissionState, submit } = useInterests()
  const event = useContext(EventContext)
  const { register, handleSubmit, errors } = useForm<{
    name: string
    lastName: string
    company: string
    email: string
    acceptsDataTerms: true
    sendProductFactSheet: true
  }>({
    mode: "onChange",
  })
  const onSubmit = handleSubmit(data => {
    submit({ ...data, event: { code: event.code, slug: event.slug! } })
  })
  return (
    <S.FormContainer
      readOnly={submissionState === "submitting"}
      onSubmit={onSubmit}
    >
      <S.Overline>{t("informationDownload")}</S.Overline>
      {submissionState !== "submitted" ? (
        <>
          <S.Title>{t("formHeading")}</S.Title>
          <S.Text>{t("formInfo")}</S.Text>

          <S.InputWrapper>
            <S.Input
              ref={register({ required: t("required") })}
              name={"name"}
              placeholder={t("firstName") + "*"}
            />
            <S.Error>{errors.name?.message}</S.Error>
          </S.InputWrapper>
          <S.InputWrapper>
            <S.Input
              ref={register({ required: t("required") })}
              name={"lastName"}
              placeholder={t("lastName") + "*"}
            />
            <S.Error>{errors.lastName?.message}</S.Error>
          </S.InputWrapper>
          <S.InputWrapper>
            <S.Input
              ref={register({ required: false })}
              name={"company"}
              placeholder={t("companyLabel")}
            />
            <S.Error>{errors.company?.type}</S.Error>
          </S.InputWrapper>
          <S.InputWrapper>
            <S.Input
              ref={register({ required: t("required") })}
              name={"email"}
              type={"email"}
              placeholder={t("emailAddress") + "*"}
            />
            <S.Error>{errors.email?.message}</S.Error>
          </S.InputWrapper>
          <S.InputWrapper>
            <S.Input
              ref={register()}
              name={"message"}
              inputAs={"textarea"}
              placeholder={t("message")}
            />
          </S.InputWrapper>
          <S.CheckboxContainer>
            <Checkbox
              ref={register({ required: false })}
              name={"sendProductFactSheet"}
              id={"sendFactSheet"}
            />
            <label htmlFor={"sendFactSheet"}>
              <span>{t("formCheckbox1")}</span>
            </label>
            <Checkbox
              ref={register({ required: t("required") })}
              name={"acceptsDataTerms"}
              id={"acceptsDataTreatment"}
            />
            <label htmlFor={"acceptsDataTreatment"}>
              <span>{t("formCheckbox2")}</span>
            </label>
            <S.Error>{errors.acceptsDataTerms?.message}</S.Error>

            {/*@ts-ignore*/}
            <S.SubmitButton title={"Submit"} />
          </S.CheckboxContainer>
          <S.RequiredHint>*{t("fieldsAreRequired")}</S.RequiredHint>
        </>
      ) : (
        <S.Title>{t("formSuccessMessage")}</S.Title>
      )}
    </S.FormContainer>
  )
}
