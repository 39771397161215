import React, { useEffect } from "react"
import styled from "styled-components"
import { ProductDetails } from "../../types/Product"
import { DatoCmsAsset } from "../DatoCmsAsset"
import { ProductAttachmentOptions } from "./ProductAttachmentOptions"
import device from "../../theme/devices"

const Container = styled.div<{ isPdf: boolean }>`
  > :first-child {
    width: 100%;
    height: 100%;
  }
  @media (${device.desktop}) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    > :first-child {
      width: 100%;
      height: ${props => (props.isPdf ? "100%" : "auto")};
    }
  }
`

const Copyright = styled.div`
  text-align: right;
  z-index: 19;
  font-size: 10px;
  color: white;

  position: absolute;
  bottom: 10px;
  right: 10px;

  @media (${device.desktop}) {
    position: static;
    margin-right: 15px;
    margin-top: -20px;
  }
`

export const ImageAttachment = React.forwardRef<
  HTMLDivElement,
  {
    attachment: ProductDetails["attachments"][number]
    setOptions: (options: ProductAttachmentOptions) => void
  }
>(({ attachment, setOptions, ...props }, ref) => {
  if (attachment.is3dModel)
    throw new Error(`Can't render 3d model as ImageAttachment`)
  if (!attachment.responsiveImage && !attachment.url)
    throw new Error(`Invalid image`)

  // TODO refactor (delete only) - pdf stuff moved to separate pdf attachment container
  const isPdf = attachment.mimeType.includes("pdf")

  useEffect(() => {
    setOptions(
      isPdf
        ? { type: "none" }
        : { type: "fullScreenImageViewer", image: attachment }
    )
  }, [isPdf, setOptions])

  return (
    <Container isPdf={isPdf} ref={ref} {...props}>
      <DatoCmsAsset asset={attachment} imgObjectFit={"contain"} />
      {attachment.copyright && <Copyright>{attachment.copyright}</Copyright>}
    </Container>
  )
})
