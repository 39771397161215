import * as S from "./styled"
import React from "react"

export const AttachmentsPager: React.FC<{
  visible: boolean
  count: number
  current: number
  onDotClick?: (i: number) => void
}> = ({ visible, count, current, onDotClick }) => (
  <S.Pager visible={visible}>
    {Array(count)
      .fill(0)
      .map((_, i) => (
        <S.Dot active={i === current} key={i} onClick={() => onDotClick?.(i)} />
      ))}
  </S.Pager>
)
