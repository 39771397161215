import device from "../../theme/devices"
import styled, { css } from "styled-components"

export const Pager = styled.div<{ visible: boolean }>`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 27px;
  z-index: 11;
  display: flex;
  flex-direction: row;

  @media (${device.desktop}) {
    position: fixed;
    top: 50%;
    left: auto;
    bottom: auto;
    transform: translateY(-50%);
    margin-left: 26px;
    flex-direction: column;
    transition: opacity 250ms ease-out;
    opacity: ${props => (props.visible ? 1 : 0)};
  }
`

export const Dot = styled.button<{ active: boolean }>`
  box-sizing: border-box;
  border-radius: 50%;
  background: none;
  outline: none;
  padding: 0;
  cursor: pointer;

  display: block;
  width: 10px;
  height: 10px;
  transition: all 250ms ease-out;

  :not(:last-child) {
    margin-right: 11px;
  }

  pointer-events: none;
  margin-bottom: 0;

  ${props =>
    props.active
      ? css`
          border: 1px solid ${props.theme.primaryColor};
        `
      : css`
          background: rgba(255, 255, 255, 0.5);
          border: none;
        `}

  @media (${device.desktop}) {
    pointer-events: all;
    :not(:last-child) {
      margin-bottom: 11px;
    }
  }
`
