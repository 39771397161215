import styled from "styled-components"
import { Link } from "react-router-dom"
import device from "../../theme/devices"
import { FactSheetSection } from "../FactSheetSection"
import { FactSheetContact } from "../FactSheetComponents"
import { ReactComponent as DownloadIcon } from "../../icons/ic-download.svg"

export const Container = styled.aside`
  color: ${props => props.theme.textColor};
  display: flex;
  flex-direction: column;
  transition: opacity 250ms ease-out;
  //&::-webkit-scrollbar {
  //  display: none;
  //}
  //-ms-overflow-style: none; /* IE and Edge */
  //scrollbar-width: none; /* Firefox */
`

export const InnerContainer = styled.div`
  flex: 1;
  background-color: ${props => props.theme.backgroundColor};

  @media (${device.desktop}) {
    //padding-bottom: 72px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    //&::-webkit-scrollbar {
    //  display: none;
    //}
    //-ms-overflow-style: none; /* IE and Edge */
    //scrollbar-width: none; /* Firefox */
  }
`

export const FactSheetContainer = styled.div`
  flex: 1;
  overflow-y: auto;
`

export const HeaderContainer = styled.div`
  @media (${device.desktop}) {
    padding-top: 103px;
    position: sticky;
    top: 0;
    background: white;
    z-index: 2;
  }
`

export const Overline = styled.div`
  font-size: 9px;
  font-weight: 400;
  line-height: 12px;
  color: ${props => props.theme.lightTextColor};
  margin-left: 20px;
  order: 2;
  @media (${device.desktop}) {
    order: 1;
  }
`

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  h1 {
    flex: 1;
    margin-right: 20px;
  }
`

export const Title = styled.h1`
  font-weight: bold;
  font-size: 34px;
  line-height: 34px;

  @media (${device.desktop}) {
  }
  margin: 18px 20px 10px;
  position: relative;

  padding-right: 20px;
`

export const LastModified = styled.div`
  font-weight: 300;
  font-size: 9px;
  line-height: 10px;
  margin-right: 21px;

  margin-top: 3px;
  color: #adadad;

  div {
    font-weight: 400;
  }
  @media (${device.desktop}) {
    position: absolute;
    right: 0;
    top: -12px;
  }
`

export const Platform = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 15px;
  margin: 0 20px 25px 20px;
`

export const Subtitle = styled.h2`
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
  margin-bottom: 41px;
  margin-top: 0;
  margin-left: 20px;
  order: 4;
  @media (${device.desktop}) {
    order: 3;
  }
`

export const Description = styled.div`
  font-size: 14px;
  font-weight: 300;
  line-height: 19px;
  margin: 0 54px 17px 20px;

  > :first-child {
    margin-top: 0;
  }
  > :last-child {
    margin-bottom: 0;
  }

  order: 5;
  @media (${device.desktop}) {
    order: 4;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 300;
  }
`

export const RequestMoreInfoContainer = styled.div`
  user-select: none;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
  padding: 15px 15px 13px;
  color: ${props => props.theme.primaryColor};
  font-size: 12px;
  font-weight: 400;

  span {
    margin-top: -2px;
    margin-left: -3px;
  }

  margin: 20px 10px 24px;

  order: 1;
  @media (${device.desktop}) {
    margin: 0 40px 10px 20px;
    order: 5;
  }
  cursor: pointer;
  > * {
    pointer-events: none;
  }
`

export const DownloadPdfContainer = styled.div`
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
  padding: 15px 15px 13px;
  font-size: 12px;
  font-weight: 400;

  a {
    margin-top: -2px;
    margin-left: -3px;
    text-decoration: none;
    color: ${props => props.theme.primaryColor};
    display: flex;
    align-items: center;
    span {
      position: relative;
      padding-left: 4px;
      cursor: pointer;
      line-height: 20px;
      display: inline-flex;
      align-items: center;
      height: 100%;
      color: inherit;
    }
  }

  margin: 20px 10px 24px;

  order: 1;
  @media (${device.desktop}) {
    margin: 0 40px 40px 20px;
    order: 5;
  }
  cursor: pointer;
`
export const FactSheetItem = styled(FactSheetSection)`
  margin-left: 20px;
  margin-right: 20px;
`

export const FactSheetContactDetails = styled(FactSheetContact)`
  margin-left: 20px;
  margin-right: 20px;
  padding-top: 25px;
  padding-bottom: 20px;
  border-top: 1px solid #efefef;
`

export const NextContainer = styled.div`
  min-height: 72px;
  height: 85px;
  border-top: 0.5px solid #c4c4c4;
  margin-top: auto;
  box-sizing: border-box;
  padding: 21px 30px 73px 30px;
  order: 6;
  background-color: ${props => props.theme.backgroundColor};
  @media (${device.desktop}) {
    order: 6;
    min-height: 72px;
    height: 72px;
    padding: 21px 20px 0 20px;
    position: sticky;
    bottom: 0px;
    background: white;
  }
`

export const NextOverline = styled.div`
  font-size: 9px;
  font-weight: 500;
  line-height: 7px;
`

export const NextProductTitle = styled(Link)`
  font-size: 19px;
  font-weight: 300;
  line-height: 29px;
  color: inherit;
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }

  svg {
    margin-left: 6px;
    margin-bottom: 2px;
    transform: rotate(45deg);
  }
`
export const Icon = styled(DownloadIcon)``
