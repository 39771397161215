import React, { useContext, useMemo } from "react"
import { RouteComponentProps } from "react-router-dom"
import { EventContext } from "../../../../context/EventContext"
import { Product as IProduct } from "../../../../types/Product"
import { Booth } from "../../../../types/Booth"
import { ProductDetailsLayout } from "../../../../layout/ProductDetailsLayout"

export const Product: React.FC<
  RouteComponentProps<{ booth: string; product: string }>
> = ({
  match: {
    params: { booth: boothSlug, product: productSlug },
  },
}) => {
  const event = useContext(EventContext)?.data

  if (!event) return null

  const [booth, product] = useMemo(() => {
    const booth = event.booths.find(b => b.slug === boothSlug)

    return [booth, booth?.products.find(p => p.slug === productSlug)]
  }, [event, productSlug, boothSlug])

  if (!product || !booth) return null

  let next: IProduct | Booth =
    booth.products[booth.products.findIndex(p => p.slug === product.slug) + 1]

  if (!next) {
    next = event.booths[event.booths.findIndex(b => b.slug === boothSlug) + 1]
  }

  let previous: IProduct | Booth =
    booth.products[booth.products.findIndex(p => p.slug === product.slug) - 1]
  if (!previous) {
    previous =
      event.booths[event.booths.findIndex(b => b.slug === boothSlug) - 1]
  }

  return (
    <ProductDetailsLayout
      product={product}
      boothName={booth.name}
      boothSlug={booth.slug}
      previous={previous}
      next={next}
    />
  )
}
