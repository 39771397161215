import { IDatoCmsAsset } from "../../types/Media"
import React, { useState } from "react"

import * as S from "./styled"
import { DatoCmsAsset } from "../DatoCmsAsset"
import ScrollLock from "react-scrolllock"
import { ModelAttachment } from "../../types/Product"
import { Scene } from "../Scene"
import { useTranslation } from "react-i18next"

export const FullScreenImageViewer: React.FC<{
  image: IDatoCmsAsset
  close: () => void
  visible: boolean
}> = ({ image, close, visible }) => {
  const { t } = useTranslation()
  return (
    <S.Container visible={visible}>
      <ScrollLock accountForScrollbars={true} isActive={visible} />
      <DatoCmsAsset imgObjectFit={"contain"} asset={image} />
      <S.CloseButton onClick={close}>{t("close")}</S.CloseButton>
    </S.Container>
  )
}

export const FullScreenSceneViewer: React.FC<
  ModelAttachment & { close: () => void }
> = ({ close, ...props }) => {
  const [cursor, setCursor] = useState<"grab" | "grabbing">("grab")
  const [animationControls, setAnimationControls] =
    useState<{ play: () => void; reverse: () => void }>()
  const { t } = useTranslation()

  return (
    <S.Container
      style={{ cursor }}
      onMouseUp={() => setCursor("grab")}
      onMouseDown={() => setCursor("grabbing")}
      background={"none"}
    >
      <ScrollLock />
      <Scene
        {...props}
        orbitControls
        setAnimationControls={setAnimationControls}
      />
      {animationControls?.play && (
        <S.Play onClick={animationControls.play}>{t("play")}</S.Play>
      )}
      {animationControls?.reverse && (
        <S.Reverse onClick={animationControls.reverse}>
          {t("reverse")}
        </S.Reverse>
      )}
      <S.CloseButton onClick={close}>{t("close")}</S.CloseButton>
    </S.Container>
  )
}
