import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"
import { useSpring } from "react-spring"

import * as S from "./styled"

export const FactSheetSection: React.FC<{
  initiallyExpanded?: boolean
  title: string
}> = ({ initiallyExpanded, title, children, ...props }) => {
  const [expanded, setExpanded] = useState<boolean>(initiallyExpanded || false)
  const [contentHeight, setContentHeight] = useState<number>(0)
  const maxHeightAnimation = useSpring({
    maxHeight: expanded ? contentHeight : 0,
    config: {
      duration: 250,
    },
  })
  const contentContainerRef =
    useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>

  const updateKnownContentHeight = useCallback(
    () =>
      setContentHeight(
        Math.ceil(contentContainerRef.current?.clientHeight || 0)
      ),
    []
  )

  useEffect(() => {
    updateKnownContentHeight()
    window.addEventListener("resize", updateKnownContentHeight)

    return () => window.removeEventListener("resize", updateKnownContentHeight)
  }, [children, title])
  return (
    <S.Container {...props}>
      <S.TitleRow onClick={() => setExpanded(!expanded)}>
        <S.Title expanded={expanded}>{title}</S.Title>
      </S.TitleRow>
      <S.ContentContainer style={maxHeightAnimation}>
        <S.InnerContentContainer ref={contentContainerRef}>
          {children}
        </S.InnerContentContainer>
      </S.ContentContainer>
    </S.Container>
  )
}
