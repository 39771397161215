import styled, { css } from "styled-components"

export const Container = styled.div<{ background?: "none"; visible?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  ${props =>
    props.background !== "none" &&
    css`
      background: rgba(19, 3, 4, 0.8);
    `}
  backdrop-filter: blur(20px);
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    width: 100%;
    height: 100%;
  }

  transition: all 250ms ease-out;
  ${props =>
    !props.visible &&
    css`
      opacity: 0;
      backdrop-filter: blur(0);
      pointer-events: none;
    `}
`

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: white;
  color: black;
  box-shadow: 0 0 13px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  padding: 12px 20px;
  border: none;
  outline: none;
  cursor: pointer;
`

export const Play = styled(CloseButton)`
  top: 100px;
`
export const Reverse = styled(CloseButton)`
  top: 150px;
`
