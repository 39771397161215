import React from "react"
import { Asset } from "../../../../hooks/useEventAssetsPreloader"
import GatsbyImageWithIEPolyfill from "gatsby-image/withIEPolyfill"
import { fetch } from "cross-fetch"

const Loader: React.FC<{
  asset: Asset
  onSuccess: (a: Asset) => any
  onFail: (a: Asset) => any
}> = ({ asset, onSuccess, onFail }) => {
  if (asset.type === "3d") {
    onSuccess(asset)
    return null
  }

  if (asset.content.responsiveImage) {
    return (
      <GatsbyImageWithIEPolyfill
        fluid={asset.content.responsiveImage}
        onLoad={() => onSuccess(asset)}
        onError={() => onFail(asset)}
        loading={"eager"}
      />
    )
  } else if (asset.content.video?.thumbnailUrl) {
    return (
      <img
        src={asset.content.video.thumbnailUrl}
        onLoad={() => onSuccess(asset)}
        onError={() => onFail(asset)}
        loading={"eager"}
      />
    )
  } else if (asset.content.video?.mp4Url?.startsWith("/")) {
    const src = asset.content.video.mp4Url
    console.log("FETCHING app video", src)
    fetch(src)
      .then(res => res.blob())
      .then(blob => window.URL.createObjectURL(blob))
      .then(url => sessionStorage.setItem(src, url))
      .then(() => {
        onSuccess(asset)
        window.addEventListener("beforeunload", () => {
          sessionStorage.removeItem(src)
        })
      })
      .catch(() => onFail(asset))
    return null
  }

  // Other types of attachment - loaded later on user request
  onSuccess(asset)
  return null
}

export const DefaultAssetLoader = React.memo(Loader)
