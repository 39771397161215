import React from "react"
import { useTranslation } from "react-i18next"
import styled, { css } from "styled-components"

const Container = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  p:first-child {
    margin-top: 0;
  }
  p:last-child {
    margin-bottom: 0;
  }
  margin: 0;
`

export const FactSheetText: React.FC<{ content: string }> = ({ content }) => {
  if (!content) return null
  if (content.includes("<p>") || content.includes("</"))
    return <Container dangerouslySetInnerHTML={{ __html: content }} />

  return <Container>{content}</Container>
}

export const FactSheetBullets: React.FC<{ content: string }> = ({
  content,
}) => {
  return (
    <Container as={"ul"} style={{ paddingLeft: "1.1em" }}>
      {content.split("\n").map(x => (
        <li key={x}>{x}</li>
      ))}
    </Container>
  )
}

const AdvantagesContainer = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;

  li {
    padding: 12px 9px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 300;

    div {
      margin-bottom: 8px;
      font-weight: 400;
    }
  }

  li:nth-of-type(odd) {
    background: #ececec;
  }
`
const DivisiLine = styled.span`
  :before {
    content: "";
    background: white;
    position: absolute;
    width: 399rem;
    height: 1px;
    background-color: white;
    /* z-index: 1; */
    margin-left: -30px;
  }
  position: relative;
  z-index: 0;
  margin-bottom: 15px !important;
`
export const FactSheetAdvantages: React.FC<{
  content: { advantage: { name: string }; value: string; id: string }[]
}> = ({ content }) => {
  return (
    <AdvantagesContainer>
      {content.map(c => (
        <div>
          <li key={c.id}>
            <div>{c.advantage.name}</div>
            {c.value}
          </li>
          <DivisiLine />
        </div>
      ))}
    </AdvantagesContainer>
  )
}

const Contact = styled.div`
  font-weight: normal;
  font-size: 10px;
  line-height: 11px;
  a {
    color: inherit;
    text-decoration: none;
    :hover {
      text-decoration: underline;
    }
  }

  b {
    font-weight: 600;
  }
`

export const FactSheetContact: React.FC<{
  name: string
  role: string
  email: string
  division: string
  company: string
}> = ({ name, role, email, division, company, ...props }) => {
  const { t } = useTranslation()
  return (
    <Contact {...props}>
      {name && (
        <>
          <b>{name}</b> <br />
        </>
      )}
      {role} <br />
      <a href={`mailto:${email}`}>{email}</a>
      <br />
      <br />
      {division && (
        <>
          <b>{t("division")}</b>: {division}
        </>
      )}
      <br />
      {company && (
        <>
          <b>{t("company")}</b>: {company}
        </>
      )}
    </Contact>
  )
}

const Level = styled.div<{
  last?: boolean
  progressFilled?: boolean
  complete?: boolean
}>`
  position: relative;

  ${props =>
    !props.complete &&
    css`
      > * {
        color: #b0b0b0;
      }
    `}

  :before {
    content: "";
    background: currentColor;
    height: 1px;
    width: 15px;
    position: absolute;
    top: 0;
  }

  ${props =>
    !props.last &&
    css`
      min-height: 63px;
      border-left: 1px solid currentColor;

      ${props.progressFilled &&
      css`
        :after {
          content: "";
          background: currentColor;
          border-top: 3px solid white;
          border-right: 3px solid white;
          border-left: 4px solid white;
          border-bottom: 4px solid white;
          height: 100%;
          position: absolute;
          top: 1px;
          width: 15px;
          box-sizing: border-box;
        }
      `}
    `}
`

const LevelTitle = styled.div`
  margin-top: -11px;
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  font-weight: normal;
  font-size: 8px;
  line-height: 9px;
  align-items: center;
`

const LevelNumber = styled.div`
  width: 23px;
  height: 23px;
  background: currentColor;
  border-radius: 50%;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 3px;

  span {
    color: white;
  }
`

export const FactSheetStatus: React.FC<{
  status: number
  allLevels: { number: number; name: string; subtitle: string }[]
}> = ({ status, allLevels }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", paddingTop: 15 }}>
      {allLevels.map((level, i) => (
        <Level
          key={level.number}
          last={i === allLevels.length - 1}
          progressFilled={level.number < status}
          complete={level.number <= status}
        >
          <LevelTitle>
            <LevelNumber>
              <span>{level.number}</span>
            </LevelNumber>
            <div>
              {level.name}
              <br />
              {level.subtitle}
            </div>
          </LevelTitle>
        </Level>
      ))}
    </div>
  )
}
