import { useEffect, useMemo, useState } from "react"
import { AnimationClip, AnimationMixer, Group, LoopOnce } from "three"
import { useFrame } from "@react-three/fiber"

export const useSceneAnimation = (
  scene?: Group,
  animations?: AnimationClip[]
) => {
  const [mixer, setMixer] = useState<AnimationMixer>()
  const [multiplier, setMultiplier] = useState<number>(1)

  useEffect(() => {
    if (!scene || !animations) return
    const _mixer = new AnimationMixer(scene)
    animations.forEach(clip => {
      const action = _mixer.clipAction(clip)
      action.reset()
      action.clampWhenFinished = true
      action.loop = LoopOnce
      action.timeScale = 1
    })
    setMixer(_mixer)
  }, [scene, animations])

  useFrame(
    (_, delta) => mixer && mixer?.update(delta * mixer?.timeScale * multiplier)
  )

  const play = useMemo(() => {
    if (!mixer || !animations || animations.length === 0) return null
    return () => {
      console.log("Play called")
      animations.forEach(animation => {
        const action = mixer.existingAction(animation)
        if (!action) return null
        action.reset()
        action.clampWhenFinished = true
        action.loop = LoopOnce
        action.timeScale = 1
        action.play()
      })
    }
  }, [mixer, animations])

  const reverse = useMemo(() => {
    if (!mixer || !animations || animations.length === 0) return null
    return () =>
      animations.forEach(animation => {
        const action = mixer.existingAction(animation)
        if (!action) return null
        action.timeScale = -1
        action.paused = false
      })
  }, [mixer, animations])

  return useMemo(() => ({ play, reverse }), [play, reverse])
}
