import { Group, Mesh } from "three"
import { useCallback, useMemo, useState } from "react"
import { Tween } from "@tweenjs/tween.js"

export const useSceneTransparency = (scene?: Group) => {
  const materials = useMemo(() => {
    if (!scene) return []
    const targets = scene.children.flatMap(c => [c, ...c.children])?.filter(
      c => c.name.toLowerCase().includes("transparent") && c.type === "Mesh"
    ) as Mesh[]

    return targets
      .map(t => (Array.isArray(t.material) ? t.material : [t.material]))
      .reduce((acc, curr) => [...acc, ...curr], [])
  }, [scene])

  const [lastValue, setLastValue] = useState<number>(1)
  const changeOpacity = useCallback(
    (target: number) => {
      const baseOpacity = materials[0].opacity
      const config = { opacity: baseOpacity }
      let animating: boolean = true
      const tween = new Tween(config)
        .to({ opacity: target })
        .onUpdate(() => {
          materials.forEach(mt => {
            mt.transparent = true
            mt.opacity = config.opacity
          })
        })
        .start()
        .onComplete(() => (animating = false))
      const animate = (time: number) => {
        tween.update(time)
        if (animating) requestAnimationFrame(animate)
      }
      setLastValue(target)
      requestAnimationFrame(animate)
    },
    [materials]
  )

  return [lastValue, materials && materials.length > 0 ? changeOpacity : null]
}
