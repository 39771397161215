import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { PerspectiveCamera as PerspectiveCameraImpl } from "three/src/cameras/PerspectiveCamera"
import { DirectionalLight } from "three"
import { OrbitControls, PerspectiveCamera } from "@react-three/drei"
import { OrbitControls as OrbitControlsImpl } from "three-stdlib"
import * as TWEEN from "@tweenjs/tween.js"

const CameraContext = createContext<{
  camera: PerspectiveCameraImpl | undefined
  controls: OrbitControlsImpl | undefined
}>({
  camera: undefined,
  controls: undefined,
})

export const useCamera = () => useContext(CameraContext)

export const Camera: React.FC<{
  orbitControls?: boolean
  autoRotate?: boolean
  x?: number
  y?: number
  z?: number
  withControlsCameraX?: number
  withControlsCameraZ?: number
  withControlsCameraY?: number
}> = ({
  orbitControls,
  autoRotate,
  children,
  x = 0,
  y = 0,
  z = 0,
  withControlsCameraX = 0,
  withControlsCameraY = 0,
  withControlsCameraZ = 0,
}) => {
  const [camera, set] = useState<PerspectiveCameraImpl>()
  const [controls, setControls] = useState<OrbitControlsImpl>()
  const [light, setLight] = useState<DirectionalLight>()

  useEffect(() => {
    if (!controls) return
    controls.addEventListener("change", () => {
      if (camera?.position) light?.position.copy(camera.position)
    })
  }, [controls])

  const position: [number, number, number] = useMemo(
    () => [x || 0, y || 0, z || 0],
    [x, y, z]
  )

  useEffect(() => {
    if (!controls || !camera) return
    const coords = {
      x: camera.position.x,
      y: camera.position.y,
      z: camera.position.z,
    }
    let completed: boolean = false
    const tween = new TWEEN.Tween(coords)
      .to({
        x: orbitControls ? withControlsCameraX : x,
        y: orbitControls ? withControlsCameraY : y,
        z: orbitControls ? withControlsCameraZ : z,
      })
      .onUpdate(() => {
        camera.position.set(coords.x, coords.y, coords.z)
        controls.update()
      })
      .start()
      .onComplete(() => (completed = true))

    const animate = (time: number) => {
      tween.update(time)
      if (!completed) requestAnimationFrame(animate)
    }
    requestAnimationFrame(animate)

    return () => {
      completed = true
    }
  }, [orbitControls, controls])

  return (
    <>
      <PerspectiveCamera makeDefault ref={set} position={position} />
      <directionalLight ref={setLight} color="white" position={position} />
      {camera && (orbitControls || autoRotate) && (
        <OrbitControls
          //@ts-ignore
          ref={setControls}
          camera={camera}
          autoRotate={autoRotate}
        />
      )}
      <CameraContext.Provider value={{ camera, controls }}>
        {children}
      </CameraContext.Provider>
    </>
  )
}
