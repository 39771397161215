import React, { Suspense } from "react"
import { Canvas } from "@react-three/fiber"
import { Html, useProgress } from "@react-three/drei"
//@ts-ignore
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"
//@ts-ignore
import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader"
//@ts-ignore
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader"
import { ModelAttachment } from "../../types/Product"
import { Camera } from "./Camera"
import { AnimationControls, Glb } from "./Glb"
import { BounceLoader } from "react-spinners"

const Loader: React.FC<{ onLoaded?: () => void }> = ({ onLoaded }) => {
  const { progress, loaded, total } = useProgress()
  console.log(progress, loaded, total)
  return (
    <Html center>
      <BounceLoader size={80} color={"#DA2020"} />
    </Html>
  )
}

export const Scene: React.FC<
  ModelAttachment & {
    onLoaded?: () => void
    orbitControls?: boolean
    rotating?: boolean
    setAnimationControls?: (controls?: AnimationControls) => void
  }
> = ({
  model,
  __typename,
  orbitControls,
  rotating,
  setAnimationControls,
  cameraX,
  cameraY,
  cameraZ,
  cameraXFullScreen,
  cameraYFullScreen,
  cameraZFullScreen,
  onLoaded,
}) => {
  return (
    <Canvas>
      <ambientLight />
      <Camera
        orbitControls={orbitControls}
        autoRotate={rotating}
        x={cameraX}
        y={cameraY}
        z={cameraZ}
        withControlsCameraX={cameraXFullScreen}
        withControlsCameraY={cameraYFullScreen}
        withControlsCameraZ={cameraZFullScreen}
      >
        <Suspense fallback={<Loader />}>
          {__typename === "Model3dRecord" && (
            <Glb
              onLoaded={onLoaded}
              url={model}
              setAnimationControls={setAnimationControls}
            />
          )}
        </Suspense>
      </Camera>
    </Canvas>
  )
}
