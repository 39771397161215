import { PrimitiveProps, useFrame } from "@react-three/fiber"
import { MutableRefObject } from "react"

export const useObjectRotation: (
  primitiveRef: MutableRefObject<PrimitiveProps | undefined>,
  enabled: boolean
) => void = (ref, enabled) => {
  useFrame(({ clock }) => {
    if (!enabled || !ref || !ref.current || !ref.current.rotation) return
    ref.current.rotation.y = clock.elapsedTime * 0.4
  })
}
