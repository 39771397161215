import styled, { createGlobalStyle, css } from "styled-components"
import device from "../../theme/devices"
import { ProductDetailsSidebar } from "../../components/ProductDetailsSidebar"
import { ProductDetailsFooter } from "../../components/ProductDetailsFooter"
import { IconButton } from "../../components/IconButton"

const HIDE_SIDEBAR_ANIMATION_DURATION = 500

export const Global = createGlobalStyle`html, body {
  @media (${device.desktop}) {
    scroll-snap-type: y ${
      process.env.GATSBY_FOR_ANDROID ? "mandatory" : "proximity"
    };
  }
}`

export const Container = styled.div`
  grid-column: 1 / 14;
  position: relative;
  display: flex;
  flex-direction: column;
`

export const SidebarContainer = styled.div<{ notClickable: boolean }>`
  ${props =>
    props.notClickable &&
    css`
      pointer-events: none;
      #nextContainer {
        pointer-events: auto;
      }
    `}
  @media (${device.desktop}) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 28.2%;
    z-index: 5;
    margin-top: -53px;
  }
`

export const SidebarIntermediateContainer = styled.div`
  @media (${device.desktop}) {
    position: sticky;
    top: 0;
  }
`

export const SidebarInnerContainer = styled.div`
  overflow-x: hidden;
`

export const Sidebar = styled(ProductDetailsSidebar)<{ visible?: boolean }>`
  margin-left: 0;
  margin-top: 0;

  @media (${device.desktop}) {
    height: 100vh;
    display: flex;
    flex-direction: column;
  }

  #sidebarInnerContainer {
    transition: transform ${HIDE_SIDEBAR_ANIMATION_DURATION}ms ease-out;
    ${props =>
      !props.visible &&
      css`
        transform: translateX(101%);
        pointer-events: none;
      `}
  }
`

export const AttachmentsContainerOuter = styled.div`
  margin-top: -53px;
  position: relative;
  width: 100vw;

  @media (${device.desktop}) {
    width: 100%;
  }
`

export const AttachmentsContainer = styled.div`
  height: 449px;
  width: 100vw;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  scroll-snap-type: x mandatory;

  @media (${device.desktop}) {
    height: auto;
    overflow: visible;
    flex-direction: column;
    scroll-snap-type: unset;

    width: 100%;
    z-index: 4;
  }
`

export const BaseAttachment = styled.div`
  scroll-snap-align: start;
  background: rgba(19, 3, 4, 0.8);
  backdrop-filter: blur(20px);
  @media (${device.desktop}) {
    height: calc(100vh - 72px);
    backdrop-filter: unset;
    background: none;
  }
`

export const RegularAttachmentComponent = styled(BaseAttachment)`
  min-width: 100vw;
  @media (${device.desktop}) {
    width: 71.8%;
    min-width: 0;
  }
`

export const SceneAttachmentComponent = styled(BaseAttachment)<{
  isFullScreen?: boolean
}>`
  width: 100%;
  transition: all ${HIDE_SIDEBAR_ANIMATION_DURATION}ms ease-out;
  transform: translateX(${props => (props.isFullScreen ? 0 : -14.1)}%);
  // On full screen, transform translateX = 0

  @media (${device.desktop}) {
    height: 100vh;
    margin-bottom: -72px;
  }
`

export const Footer = styled(ProductDetailsFooter)<{ hide?: boolean }>`
  width: 100%;
  box-sizing: border-box;
  @media (${device.desktop}) {
    position: sticky;
    bottom: 0;
    height: 72px;
    width: 71.8%;
    opacity: ${props => (props.hide ? 0 : 1)};
    transition: all ${HIDE_SIDEBAR_ANIMATION_DURATION}ms ease-out;
    border-top: 0.5px solid #c4c4c4;
  }
`

export const FullScreenButton = styled(IconButton)<{ visible?: boolean }>`
  position: absolute;
  bottom: auto;
  top: 330px;
  right: 30px;

  transition: all 250ms ease-out;
  ${props =>
    !props.visible &&
    css`
      opacity: 0;
      pointer-events: none;
    `}

  @media (${device.desktop}) {
    position: fixed;
    bottom: 90px;
    top: auto;
    right: 28%;
  }
`
