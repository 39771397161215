import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { EventContext } from "../context/EventContext"
import { IDatoCmsAsset } from "../types/Media"
import { ModelAttachment } from "../types/Product"

export type Asset = {
  itemId: string
} & (
  | { type: "media"; content: IDatoCmsAsset }
  | { type: "3d"; content: ModelAttachment }
)

type Progress = { itemId: string; status?: "ready" | "failed" }

export const useEventAssetsPreloader = () => {
  const eventContext = useContext(EventContext)
  const [[assets, statuses], setAssets] = useState<[Asset[], Progress[]]>([
    [],
    [],
  ])

  useEffect(() => {
    setAssets([[], []])
    if (!eventContext.data) {
      return
    }
    const items: Asset[] = []
    eventContext.data.booths.forEach(b => {
      if (b.poster.media?.url) {
        items.push({
          type: "media",
          itemId: b.poster.media.url,
          content: b.poster.media,
        })
      }

      b.products.forEach(p => {
        if (p.thumbnail?.media.url) {
          items.push({
            type: "media",
            itemId: p.thumbnail.media.url,
            content: p.thumbnail.media,
          })
        }

        p.details.attachments.forEach(at => {
          if ("model" in at) {
            items.push({
              type: "3d",
              itemId: at.model,
              content: at,
            })
          } else {
            items.push({
              type: "media",
              itemId: at.url,
              content: at,
            })
          }
        })
      })
    })
    ;["Intro", "Side_1", "Side_2", "Side_3", "Side_4"]
      .map(
        url => `/${process.env.GATSBY_FOR_ANDROID ? "assets/" : ""}${url}.mp4`
      )
      .forEach(url => {
        items.push({
          type: "media",
          itemId: url,
          content: {
            url,
            filename: "static",
            mimeType: "video/mp4",
            video: {
              mp4Url: url,
            },
          },
        })
      })

    const uniqueAssets = items.reduce((acc, curr) => {
      if (!acc.some(x => x.itemId === curr.itemId)) {
        acc.push(curr)
      }

      return acc
    }, [] as Asset[])

    setAssets([uniqueAssets, uniqueAssets.map(ak => ({ itemId: ak.itemId }))])
  }, [eventContext.data])

  const onAssetReady = useCallback(
    (asset: Asset, status: Progress["status"]) =>
      setAssets(([assets, states]) => [
        assets,
        states.map(s =>
          s.itemId === asset.itemId
            ? {
                ...s,
                status,
              }
            : s
        ),
      ]),
    []
  )
  const onSuccess = useCallback(
    (asset: Asset) => onAssetReady(asset, "ready"),
    [onAssetReady]
  )
  const onFailure = useCallback(
    (asset: Asset) => onAssetReady(asset, "failed"),
    [onAssetReady]
  )

  const progress = useMemo(() => {
    if (!statuses || statuses.length === 0) {
      return 0
    }

    const readyCount = statuses.filter(s => s.status === "ready").length
    const failureCount = statuses.filter(s => s.status === "failed").length

    return (readyCount + failureCount) / statuses.length
  }, [statuses])

  return {
    assets,
    onSuccess,
    onFailure,
    progress,
    eventSlug: eventContext.slug,
  }
}
