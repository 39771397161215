import styled, { css } from "styled-components"
import React from "react"
import device from "../../theme/devices"

export const RoundButton = styled.button`
  background: #ffffff;
  box-shadow: 0 0 13px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  height: 40px;

  :focus {
    outline: none;
  }
`

export const Button = styled(RoundButton)<{ isLink?: boolean }>`
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  user-select: none;
  i {
    min-width: 31px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  i {
    :first-child {
      transform: rotate(180deg);
    }
  }

  div {
    max-width: 0;
    overflow: hidden;
    transition: max-width 250ms ease-out;
    white-space: nowrap;

    &:last-child:first-child {
      max-width: none;
      margin: 0 12px;
    }
  }
  ${props =>
    props.isLink &&
    css`
      cursor: pointer;
      @media (${device.desktop}) {
        &:hover {
          > :not(i) {
            max-width: 220px;
            :first-child {
              margin-left: 20px;
            }

            :last-child {
              margin-right: 20px;
            }
          }
        }
      }
    `}
`

export const IconButton: React.FC<{
  icon: JSX.Element
  label: string
  onClick: () => void
}> = ({ icon, label, onClick, ...props }) => {
  return (
    <Button onClick={onClick} isLink {...props}>
      <i>{icon}</i>
      <div>{label}</div>
    </Button>
  )
}
