import styled, { css } from "styled-components"
import device from "../../theme/devices"
//@ts-ignore
import { ReactComponent as Arrow } from "../../icons/ic-arrow-top-right.svg"
import { Link } from "react-router-dom"

export const Container = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  color: ${props => props.theme.textColor};

  z-index: 8;
  background: ${props => props.theme.backgroundColor};

  padding: 14px 20px 15px 19px;

  @media (${device.desktop}) {
    padding: 21px 20px 0 26px;
  }
`

export const Previous = styled.div`
  grid-row: 1;
  grid-column: 1;
  display: none;

  @media (${device.desktop}) {
    display: block;
  }
`

export const PreviousOverline = styled.div`
  font-size: 9px;
  font-weight: 500;
  line-height: 7px;
`

export const ArrowLeft = styled(Arrow)`
  transform: rotate(-135deg);
`

export const PreviousTitle = styled(Link)`
  font-size: 19px;
  font-weight: 300;
  line-height: 29px;
  color: inherit;
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
  cursor: pointer;

  svg {
    margin-right: 6px;
    margin-bottom: 2px;
  }
`

export const OtherItems = styled.div`
  grid-row: 1;
  grid-column: 2;
`

export const ConfidentialLabel = styled.div<{ visible: boolean }>`
  grid-row: 1;
  grid-column: 3;
  height: 28px;
  padding-left: 10px;
  min-width: 127px;
  font-size: 9px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0;
  color: white;
  background: ${props => props.theme.primaryColor};
  display: flex;
  flex-direction: row;
  img {
    width: 18px;
    margin-right: 6px;
  }
  transition: opacity 250ms ease-out;

  ${props =>
    !props.visible &&
    css`
      opacity: 0;
    `};
`
