import React, { useEffect, useState } from "react"
import styled, { css } from "styled-components"
import { ProductDetails } from "../../types/Product"
import { Scene } from "../Scene"
import { ProductAttachmentOptions } from "./ProductAttachmentOptions"
import ScrollLock from "react-scrolllock"
import { IconButton, RoundButton } from "../IconButton"
import { AnimationControls } from "../Scene/Glb"
//@ts-ignore
import { ReactComponent as ExitFullScreenIcon } from "../../icons/ic-exit-full-screen.svg"

const Container = styled.div<{ isFullScreen?: boolean; showScene?: boolean }>`
  position: relative;
  ${props =>
    props.onClick &&
    css`
      cursor: pointer;
    `}
  canvas {
    width: 100% !important;
    height: 100% !important;
    pointer-events: ${props => !props.isFullScreen && "none"};
    transition: opacity 250ms ease-out, transform 1500ms ease-out;
    ${props =>
      props.showScene
        ? css``
        : css`
            opacity: 0;
            transform: scale(0.2);
          `}
  }
  ${props =>
    props.isFullScreen &&
    css`
      cursor: grab;
      :active {
        cursor: grabbing;
      }
    `}
`

export const ActionsContainer = styled.div<{ visible?: boolean }>`
  position: absolute;
  bottom: 92px;
  right: 40px;
  transition: opacity 1000ms ease-out;
  display: flex;
  flex-direction: column;
  align-items: end;

  > :not(:first-child) {
    margin-top: 15px;
  }
`

const ActionButton = styled(RoundButton)<{ active?: boolean }>`
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  padding: 12px 20px;
  transition: all 250ms ease-out;
  cursor: pointer;
  min-width: 120px;

  ${props =>
    props.active &&
    css`
      background: ${props.theme.primaryColor};
      color: white;
    `}
`

export const SceneAttachment = React.forwardRef<
  HTMLDivElement,
  {
    attachment: ProductDetails["attachments"][number]
    setOptions: (options: ProductAttachmentOptions) => void
    isFullScreen?: boolean
    gotVisible: boolean
    setFullScreen?: (fullScreen: boolean) => void
  }
>(
  (
    {
      attachment,
      isFullScreen,
      setOptions,
      gotVisible,
      setFullScreen,
      ...props
    },
    ref
  ) => {
    if (!attachment.is3dModel || !attachment.model)
      throw new Error(`Invalid model`)

    useEffect(() => {
      setOptions({ type: "hideSidebar" })
    }, [attachment, setOptions])

    const [loaded, setLoaded] = useState<boolean>()

    const [animationControls, setAnimationControls] =
      useState<AnimationControls>()
    const [flyoutOn, setFlyoutOn] = useState<boolean>(false)
    const [opacity, _setOpacity] = useState<number>(1)

    useEffect(() => {
      if (!isFullScreen) {
        setFlyoutOn(false)
        animationControls?.reverse?.()
        animationControls?.setOpacity?.(1)
        _setOpacity(1)
      }
    }, [isFullScreen])

    return (
      <Container
        ref={ref}
        isFullScreen={isFullScreen}
        //@ts-ignore
        onClick={
          loaded &&
          gotVisible &&
          !isFullScreen &&
          setFullScreen &&
          (() => setFullScreen(true))
        }
        showScene={loaded && gotVisible}
        {...props}
      >
        <ScrollLock accountForScrollbars={false} isActive={isFullScreen} />
        <Scene
          onLoaded={() => setLoaded(true)}
          rotating={!isFullScreen}
          orbitControls={isFullScreen}
          setAnimationControls={setAnimationControls}
          {...attachment}
        />
        <ActionsContainer visible={isFullScreen}>
          {animationControls?.play && animationControls?.reverse && (
            <ActionButton
              active={flyoutOn}
              onClick={() => {
                if (flyoutOn) animationControls?.reverse?.()
                else animationControls?.play?.()
                setFlyoutOn(s => !s)
              }}
            >
              Flyout
            </ActionButton>
          )}
          {animationControls?.setOpacity && (
            <ActionButton
              active={opacity < 1}
              onClick={() => {
                animationControls?.setOpacity?.(opacity === 1 ? 0.4 : 1)
                _setOpacity(opacity === 1 ? 0.4 : 1)
              }}
            >
              View inside
            </ActionButton>
          )}

          {setFullScreen && (
            <IconButton
              icon={<ExitFullScreenIcon />}
              label={"Exit fullscreen"}
              onClick={() => setFullScreen(false)}
            />
          )}
        </ActionsContainer>
      </Container>
    )
  }
)
