import React, { useEffect } from "react"
import styled from "styled-components"
import { ProductDetails } from "../../types/Product"
import { DatoCmsAsset } from "../DatoCmsAsset"
import { ProductAttachmentOptions } from "./ProductAttachmentOptions"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  video {
    object-fit: contain;
  }
  > * {
    height: 100%;
    width: 100%;
  }
`

export const VideoAttachment = React.forwardRef<
  HTMLDivElement,
  {
    attachment: ProductDetails["attachments"][number]
    setOptions: (options: ProductAttachmentOptions) => void
  }
>(({ attachment, setOptions, ...props }, ref) => {
  if (attachment.is3dModel)
    throw new Error(`Can't render 3d model as ImageAttachment`)
  if (!attachment.video) throw new Error(`Invalid video`)

  useEffect(() => {
    setOptions({ type: "none" })
  }, [attachment, setOptions])

  return (
    <Container ref={ref} {...props}>
      <DatoCmsAsset asset={attachment} videoProps={{ eager: false }} />
    </Container>
  )
})
