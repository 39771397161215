import styled, { css } from "styled-components"
import { animated } from "react-spring"

export const Container = styled.section`
  border-top: 1px solid #efefef;
  color: ${props => props.theme.textColor}:;
`

export const TitleRow = styled.div`
  padding: 11px 0 12px 0;
  cursor: pointer;
`

export const Title = styled.h2<{ expanded?: boolean }>`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin: 0;
  position: relative;

  :before {
    content: "";
    position: absolute;
    right: 4px;
    height: 9px;
    width: 1px;
    background: currentColor;
    top: 6px;
    transform-origin: center center;
    transition: all 250ms ease-out;
    ${props =>
      props.expanded &&
      css`
        transform: rotate(90deg);
      `}
  }

  :after {
    content: "";
    position: absolute;
    right: 0;
    top: 10px;
    width: 9px;
    background: currentColor;
    height: 1px;
  }
`

export const ContentContainer = styled(animated.div)`
  overflow: hidden;
`

export const InnerContentContainer = styled.div`
  padding-bottom: 25px;
`
