import { ProductDetails } from "../../types/Product"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useInView } from "react-intersection-observer"
import { VideoAttachment } from "./VideoAttachment"
import { ImageAttachment } from "./ImageAttachment"
import { SceneAttachment } from "./SceneAttachment"
import { ProductAttachmentOptions } from "./ProductAttachmentOptions"
import { PdfAttachment } from "./PdfAttachment"

export const ProductAttachment: React.FC<{
  attachment: ProductDetails["attachments"][number]
  onVisible: (options?: ProductAttachmentOptions) => void
  as?: React.ElementType
  isFullScreen?: boolean
  setFullScreen?: (fullScreen: boolean) => void
}> = ({ attachment, onVisible, setFullScreen, ...props }) => {
  const { ref: inViewRef, inView } = useInView({
    threshold: 0.6,
  })
  const ref = useRef<HTMLElement>()
  const setRef = useCallback(r => {
    inViewRef(r)
    ref.current = r
  }, [])

  const [options, setOptions] = useState<ProductAttachmentOptions>()
  const [wasVisible, setWasVisible] = useState<boolean>(false)

  useEffect(() => {
    if (inView) {
      onVisible(options)
      setWasVisible(true)
      ;((ref.current?.firstChild as HTMLElement) || ref.current)?.focus()
    }
  }, [inView])

  if (attachment.is3dModel) {
    return (
      <SceneAttachment
        ref={setRef}
        setOptions={setOptions}
        attachment={attachment}
        gotVisible={wasVisible}
        setFullScreen={setFullScreen}
        {...props}
      />
    )
  }

  if (attachment.video) {
    return (
      <VideoAttachment
        ref={setRef}
        attachment={attachment}
        setOptions={setOptions}
        {...props}
      />
    )
  }

  if (attachment.mimeType.includes("pdf")) {
    return (
      <PdfAttachment
        ref={setRef}
        attachment={attachment}
        setOptions={setOptions}
        {...props}
      />
    )
  }

  return (
    <ImageAttachment
      ref={setRef}
      attachment={attachment}
      setOptions={setOptions}
      {...props}
    />
  )
}
